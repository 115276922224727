import { Component, Event, Prop, State, h } from '@stencil/core';
import months from '../../utils/months';
import { PTZCalendarRangeTypes } from '../ptz-calendar-range/types/ptz-calendar-range.types';

@Component({
  tag: 'ptz-calendar-range',
  styleUrl: 'ptz-calendar-range.scss',
})
export class PTZCalendarRange {
  /** Valor padrão do ínicio da data */
  @Prop() defaultValueStartDate: PTZCalendarRangeTypes.Args['defaultValueStartDate'];

  /** Valor padrão do fim da data */
  @Prop() defaultValueEndDate: PTZCalendarRangeTypes.Args['defaultValueEndDate'];

  /** Propriedade que define se o calendário terá sombra.  */
  @Prop() boxShadow?: PTZCalendarRangeTypes.Args['boxShadow'] = false;

  /**  Controla a visibilidade do calendário anual. **/
  @State() showYearCalendar: boolean = false;

  /**  Armazena a lista de dias, utilizada para exibir os dias no calendário. **/
  @State() daysList: PTZCalendarRangeTypes.CalendarDay[] = [];

  /**  Índice do dia selecionado atualmente na lista de dias. **/
  @State() currentSelectDayIndex: number;

  /**  Rótulo que exibe o mês e o ano atual no calendário. **/
  @State() currentMonthYearLabel: string;

  /**  Data de início selecionada no calendário. **/
  @State() selectedStartDate: Date = null;

  /**  Data de término selecionada no calendário. **/
  @State() selectedEndDate: Date = null;

  /**  Data para adicionar o hover background nos dias. **/
  @State() hoverDate: Date = null;

  /**  Armazena o ano atual. **/
  @State() private year: number = new Date().getFullYear();

  /**  Armazena o mês atual. **/
  @State() private month: number = new Date().getMonth();

  /** Callback para receber os valores do calendario */
  @Event({ eventName: 'calendarChange' }) onCalendarChange?: PTZCalendarRangeTypes.Args['onCalendarChange'];

  componentWillLoad() {
    this.manipulate(this.year, this.month);

    this.selectDefaultDates();
  }

  private selectDefaultDates() {
    if (!this.defaultValueStartDate || !this.defaultValueEndDate) return;

    const parsedDateStart = new Date(this.defaultValueStartDate);
    const parsedDateEnd = new Date(this.defaultValueEndDate);

    if (parsedDateStart.getDate() && parsedDateEnd.getDate()) {
      const startMonth = parsedDateStart.getMonth();
      const startYear = parsedDateStart.getFullYear();
      const endMonth = parsedDateEnd.getMonth();
      const endYear = parsedDateEnd.getFullYear();

      parsedDateStart.setHours(0, 0, 0, 0);
      parsedDateEnd.setHours(0, 0, 0, 0);

      if (parsedDateStart.getDate() && parsedDateEnd.getDate()) {
        this.month = startMonth;
        this.year = startYear;
        this.selectDay(parsedDateStart.getDate());

        this.month = endMonth;
        this.year = endYear;
        this.selectDay(parsedDateEnd.getDate());
      }
    }
  }

  private get today(): Date {
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    return today;
  }

  private getFirstDayOfMonth(year: number, month: number): number {
    return new Date(year, month, 1).getDay();
  }

  private getLastDateOfMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  private getLastDayOfMonth(year: number, month: number): number {
    return new Date(year, month, this.getLastDateOfMonth(year, month)).getDay();
  }

  private getPreviousMonthLastDate(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }

  private generateCalendarHTML(year: number, month: number): void {
    let daysNewList: PTZCalendarRangeTypes.CalendarDay[] = [];

    const firstDay = this.getFirstDayOfMonth(year, month);

    const lastDate = this.getLastDateOfMonth(year, month);

    const lastDay = this.getLastDayOfMonth(year, month);

    const previousMonthLastDate = this.getPreviousMonthLastDate(year, month);

    for (let i = firstDay; i > 0; i--) {
      daysNewList.push({ status: 'inactive', value: previousMonthLastDate - i + 1 });
    }

    for (let i = 1; i <= lastDate; i++) {
      daysNewList.push({ value: i });
    }
    for (let i = lastDay; i < 6; i++) {
      daysNewList.push({ status: 'inactive', value: i - lastDay + 1 });
    }

    let counter = 0;

    daysNewList = daysNewList.map((data, index) => {
      let position = '';

      const { position: positionEdge, counter: counterUpdated } = this.getPositionForCalendarEdge(counter);

      if (this.isNextDayInactive(daysNewList, index)) {
        position = 'right';
      } else {
        position = positionEdge;
      }

      counter = counterUpdated;

      return {
        ...data,
        position,
      };
    });

    this.daysList = daysNewList;
  }

  private getPositionForCalendarEdge(counter: number) {
    let position = counter === 0 ? 'left' : counter === 6 ? 'right' : '';

    if (counter === 7) {
      position = 'left';
      counter = 0;
    }

    counter++;
    return { position, counter };
  }

  private isNextDayInactive(dayList: PTZCalendarRangeTypes.CalendarDay[], currentIndex: number): boolean {
    const nextDay = dayList[currentIndex + 1];
    return !!nextDay && nextDay.status === 'inactive';
  }

  private updateCurrentDateText(year: number, month: number): void {
    this.currentMonthYearLabel = `${months[month]} de ${year}`;
  }

  private manipulate(year: number, month: number): void {
    this.updateCurrentDateText(year, month);
    this.generateCalendarHTML(year, month);
  }

  private prevMonthClickHandler(): void {
    this.month = this.month === 0 ? 11 : this.month - 1;
    this.year = this.month === 11 ? this.year - 1 : this.year;
    this.manipulate(this.year, this.month);
  }

  private nextMonthClickHandler(): void {
    this.month = this.month === 11 ? 0 : this.month + 1;
    this.year = this.month === 0 ? this.year + 1 : this.year;
    this.manipulate(this.year, this.month);
  }

  private selectDay(day: number): void {
    const date = new Date(this.year, this.month, day);

    const isDayBeforeToday = date < this.today;

    const isYearBeforeToday = date.getFullYear() < this.today.getFullYear();

    if (isDayBeforeToday || isYearBeforeToday) {
      return;
    }

    const theDayIsMinorThanStartDate = date < this.selectedStartDate;

    date.setHours(0, 0, 0, 0);

    if (theDayIsMinorThanStartDate || !this.selectedStartDate) {
      this.selectedStartDate = date;
      this.selectedEndDate = null;
    } else {
      this.selectedEndDate = date;
      this.hoverDate = null;
    }

    this.onChangeValues();
  }

  onChangeValues() {
    this.onCalendarChange.emit([this.selectedStartDate, this.selectedEndDate]);
  }

  private getClassNames(calendarDay: PTZCalendarRangeTypes.CalendarDay): string {
    const { value, position, status } = calendarDay;

    const selectedClass = this.selectedClass(value, status);

    const selectedRange = this.selectedRange(value, status);

    const today = this.isToday(value);

    return `${selectedClass} ${selectedRange} ${position} ${today}`;
  }

  private selectedClass(value: number, status: string): string {
    if (status === 'inactive') {
      return status;
    }

    const date = new Date(this.year, this.month, value);

    date.setHours(0, 0, 0, 0);

    const selectedDateClass = date.getTime() === this.selectedStartDate?.getTime() ? 'selected ' : '';

    const selectedEndDateClass = date.getTime() === this.selectedEndDate?.getTime() ? 'selected end' : '';

    return `${selectedEndDateClass} ${selectedDateClass}`;
  }

  private selectedRange(value: number, status: PTZCalendarRangeTypes.CalendarDay['status']): string {
    if (status != 'inactive') {
      const dateIsInRange = new Date(this.year, this.month, value);

      if (dateIsInRange > this.selectedStartDate && dateIsInRange < this.hoverDate) {
        return 'range';
      }

      if (this.selectedStartDate && this.selectedEndDate) {
        if (dateIsInRange > this.selectedStartDate && dateIsInRange < this.selectedEndDate) {
          return 'range';
        }
      }
    }

    return '';
  }

  private isToday(value: number): string {
    const date = new Date(this.year, this.month, value);

    date.setHours(0, 0, 0, 0);

    return date.getTime() === this.today.getTime() ? 'today' : '';
  }

  private rangeSelected() {
    if ((this.selectedStartDate && this.selectedEndDate) || this.hoverDate) {
      return 'range-selected';
    }

    return '';
  }

  private onMouseRange(value: number, status: PTZCalendarRangeTypes.CalendarDay['status']) {
    if (status === 'inactive') {
      return;
    }

    if (this.selectedStartDate && !this.selectedEndDate) {
      const date = new Date(this.year, this.month, value);

      this.hoverDate = date;
    }
  }

  render() {
    return (
      <div class={`calendar-container ${this.boxShadow ? 'shadow' : undefined}`}>
        {this.showYearCalendar ? (
          <ptz-calendar-year
            onClickHeader={() => (this.showYearCalendar = !this.showYearCalendar)}
            labelHeader={`${months[this.month]} de ${this.year}`}
            defaultValue={this.year}
            onCalendarChange={value => {
              this.year = value.detail;
              this.manipulate(value.detail, this.month);
            }}
          />
        ) : (
          <div>
            <header class="calendar-header">
              <button class="calendar-current-date" onClick={() => (this.showYearCalendar = !this.showYearCalendar)}>
                <span> {`${months[this.month]} de ${this.year}`} </span> <ptz-icon variant="solid" size="xl" name="angle-down-solid" />{' '}
              </button>
              <div class="calendar-navigation">
                <button id="prevMonth" onClick={() => this.prevMonthClickHandler()}>
                  <ptz-icon size="lg" variant="solid" color="neutral-darker-accent" name="angle-left-solid" />
                </button>
                <button id="nextMonth" onClick={() => this.nextMonthClickHandler()}>
                  <ptz-icon size="lg" variant="solid" color="neutral-darker-accent" name="angle-right-solid" />
                </button>
              </div>
            </header>

            <div class="calendar-body">
              <ul class="calendar-weekdays">
                <li>D</li>
                <li>S</li>
                <li>T</li>
                <li>Q</li>
                <li>Q</li>
                <li>S</li>
                <li>S</li>
              </ul>
              <ul class={`calendar-dates ${this.rangeSelected()}`}>
                {this.daysList?.map(data => (
                  <li class={this.getClassNames(data)} id={data.status != 'inactive' && this.isToday(data.value)}>
                    <button
                      id={`${data.value}`}
                      onClick={data.status != 'inactive' ? () => this.selectDay(data.value) : undefined}
                      disabled={data.status == 'inactive'}
                      onMouseEnter={() => this.onMouseRange(data.value, data.status)}
                    >
                      {data.value}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}
