ptz-calendar-range {
  .shadow {
    box-shadow: $petz-shadow-sm;
  }

  .calendar-container {
    box-sizing: content-box;
    background: $petz-color-neutral-white;
    width: 327px;
    height: 356px;
    border-radius: 10px;
    padding: $petz-spacing-sm;

    header {
      display: flex;
      align-items: center;
      padding: $petz-spacing-2xs;
      justify-content: space-between;
    }
  }

  header {
    .calendar-navigation {
      display: flex;
      gap: $petz-spacing-xs;

      button {
        background: none;
        border: 1px solid $petz-color-neutral-light;
        border-radius: $petz-rounded-max;
        height: $petz-spacing-lg;
        width: $petz-spacing-lg;
      }
    }

    .calendar-current-date {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      gap: $petz-spacing-3xs;
      span {
        font-weight: bold;
        font-size: $petz-font-size-xs;
      }
    }
  }

  .calendar-weekdays {
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
    color: $petz-color-neutral-dark;
  }

  .calendar-body {
    padding: 0 10px;

    ul {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    li {
      height: 40px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      margin: $petz-spacing-4xs;
    }

    .calendar-dates {
      margin-top: 0;
      margin-bottom: $petz-spacing-3xs;
      padding-left: 0;

      li {
        cursor: pointer;

        button {
          display: flex;
          cursor: pointer;
          all: unset;
          width: 100%;
          height: 100%;
        }

        &#today {
          &:before {
            border: 1px solid $petz-color-primary-brand;
          }
        }

        &.range {
          background: #cce5f3;
          color: $petz-color-primary-brand;
          padding: 0px $petz-spacing-4xs;
          margin: $petz-spacing-4xs 0;
        }

        &.selected {
          color: $petz-color-neutral-white;
          &:before {
            background: $petz-color-primary-brand;
          }
        }

        &.end {
          &:after {
            border-radius: 0 100% 100% 0;
            left: -2px;
          }
        }

        &.inactive {
          color: $petz-color-neutral-mid;
          cursor: not-allowed;
        }
        &.active {
          color: #fff;

          &:before {
            background: $petz-color-primary-brand;
            border: $petz-color-primary-brand;
          }
        }

        &:before {
          position: absolute;
          content: '';
          z-index: -1;
          top: 50%;
          left: 50%;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }

        &:not(.active, .selected):hover::before {
          background: $petz-color-neutral-light;
        }
      }
    }

    .range-selected {
      li.left {
        border-radius: 100% 0 0 100%;
      }

      li.right {
        border-radius: 0 100% 100% 0;
      }

      .inactive + .range {
        border-radius: 100% 0 0 100%;
      }

      li {
        &.selected {
          &:after {
            background: $petz-color-primary-signature;
            width: 100%;
            height: 100%;
            border-radius: 100% 0 0 100%;
            position: absolute;
            right: -2px;
            content: '';
            z-index: -2;
          }
        }

        &.end {
          &:after {
            border-radius: 0 100% 100% 0;
            left: -2px;
          }
        }
      }
    }
  }
}
